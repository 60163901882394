.headings-h1,
.headings-h2,
.headings-h3 {
	font-weight: normal;
}

.headings-h1 {
	font-family: $font-family-heading;
	text-transform: none;
	margin-bottom: 10px;
	line-height: 1;
	color: $color-heading;
    text-align: center;

	@include media-breakpoint-up(xs) {
		font-size: $h2-size;
	}
	@include media-breakpoint-up(sm) {
		font-size: $h1-size;
	}

	&--banner {
		margin-bottom: 50px;
	}
}

.headings-h2 {
	font-family: $font-family-subheading;
	text-transform: uppercase;
	margin-bottom: 1.176em;
	line-height: 1;
	color: #1e2933;
	text-align: center;
	font-size: $h2-size;

	&--light {
		color: #ffffff;
	}
}

.headings-h3 {
	font-family: $font-family-subheading;
	line-height: 1;
	margin-bottom: 17px;
	font-size: $h3-size;
	color: $color-heading;
}
