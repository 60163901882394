.type-car {
	max-width: 80%;
	margin: 0 auto;
	padding-bottom: 20px;
	border-bottom: 1px solid #1e2933;

	&:last-child {
		border-bottom: none;
	}

	&__heding {
		text-align: center;
		padding: 15px;
		padding-bottom: 25px;
		font-size: 20px;
	}

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		img {
			margin: 0 20px 15px;


		}
	}

	&__content {
	}

	&__row {
		display: flex;
	}

	&__col {
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 5px;
		flex: 0 0 50%;
		max-width: 50%;

		&--one {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}
