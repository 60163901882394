.but {
	cursor: pointer;
	border: none;
	display: inline-block;
	height: 45px;
	line-height: 52px;
	background: #78d1ca;
	box-shadow: 2px 2px 0 #75a8a4;
	border-radius: 3px;
	padding: 0 20px;
	font-size: 20px;
	font-family: "PTSansBold";
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	text-shadow: 1px 1px 3px #4ea29b;
}
.but.grey {
	background: #b6b6b6;
	text-shadow: 1px 1px 3px #858383;
	box-shadow: 2px 2px 0 #898989;
}

.but:hover {
	background: #6abcb6;
	box-shadow: 2px 2px 0 #5f8b88;
	transition: all 200ms linear;
	-o-transition: all 200ms linear;
	-moz-transition: all 200ms linear;
	-webkit-transition: all 200ms linear;
}

.but.pink {
	background-color: #146db7;
	border-color: #045169;
	text-shadow: none;
	box-shadow: none;
}
.but.pink:hover {
	background: #045169;
}
