///* ========================================================================
//   #FONT-FACES
//   ======================================================================== */


@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('../fonts/subset-Roboto-MediumItalic.eot');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
        url('../fonts/subset-Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/subset-Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('../fonts/subset-Roboto-LightItalic.eot');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
        url('../fonts/subset-Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-LightItalic.woff') format('woff'),
        url('../fonts/subset-Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('../fonts/subset-Roboto-ThinItalic.eot');
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
        url('../fonts/subset-Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/subset-Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/subset-Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('../fonts/subset-Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Medium.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Medium.woff') format('woff'),
        url('../fonts/subset-Roboto-Medium.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('../fonts/subset-Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('../fonts/subset-Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Thin.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Thin.woff') format('woff'),
        url('../fonts/subset-Roboto-Thin.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/subset-Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('../fonts/subset-Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Regular.woff') format('woff'),
        url('../fonts/subset-Roboto-Regular.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/subset-Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('../fonts/subset-Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Light.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Light.woff') format('woff'),
        url('../fonts/subset-Roboto-Light.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../fonts/subset-Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'),
        url('../fonts/subset-Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Black.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Black.woff') format('woff'),
        url('../fonts/subset-Roboto-Black.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/subset-Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/subset-Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Bold.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Bold.woff') format('woff'),
        url('../fonts/subset-Roboto-Bold.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('../fonts/subset-Roboto-BlackItalic.eot');
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
        url('../fonts/subset-Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/subset-Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url('../fonts/subset-Roboto-Italic.eot');
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url('../fonts/subset-Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-Italic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Italic.woff') format('woff'),
        url('../fonts/subset-Roboto-Italic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url('../fonts/subset-Roboto-BoldItalic.eot');
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
        url('../fonts/subset-Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/subset-Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/subset-Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/subset-Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

