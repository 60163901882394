.form {

	input[type="text"], input[type="tel"] {
		width: 100%;
		background: #fff;
		border: none;
		height: 47px;
		line-height: 47px;
		padding: 0 20px;
		font-size: 15px;
		color: #737373;
		border-radius: 2px;

		@include media-breakpoint-up(xs) {
			margin-bottom: 10px;
		}

		@include media-breakpoint-up(sm) {
			margin-bottom: 0;
		}
	}

	.but {
		width: 100%;
		height: 47px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}