.directions {
	display: flex;
	align-items: center;

	@include media-breakpoint-up(xs) {
		margin-bottom: 25px;
	}
	@include media-breakpoint-up(sm) {
		margin: 0;
	}

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 75px;
		height: 75px;

		img {
			max-height: 100%;
		}
	}

	&__content {
		padding-left: 20px;
		font-family: "Roboto-Medium", sans-serif;
	}
}
