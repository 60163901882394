.popup {
	&_bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		visibility: hidden;
		z-index: 99;
	}

	&.sl_form {
		position: fixed;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
		margin: 0 auto;
		visibility: hidden;
		z-index: 99;
		right: 0;
		width: 340px;
		padding: 40px;
		border-radius: 3px;
		background-color: #1e2933;

		.name {
			color: #fff;
			text-shadow: none;
		}
	}

	&_bg.active,
	&.active {
		visibility: visible;
	}
}
