///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 2px !default;
$global-transition: all 150ms ease-in-out !default;
